<template>
  <header class="header-container">
    <div class="sign">
      <a target="_blank" href="https://www.cnknowledge.com/index">
        <img
          style="cursor: pointer; width: 70px; margin-right: 20px"
          v-lazy="require('@/assets/imgs/zwzsw.png')"
          alt=""
        />
      </a>
      <router-link to="/"
        ><img :src="require('@/assets/imgs/bjlogo.png')" alt=""
      /></router-link>
    </div>
    <div v-if="!token" class="user">
      <span @click="login(1)" class="login">登录</span>
      <span @click="login(0)" class="register">注册</span>
    </div>
    <div class="user" v-else>
      <Dropdown @on-click="userClick">
        <img
          v-if="userInfo.headPhoto"
          v-lazy="
            userInfo.headPhoto
              ? $global.imgUrl + userInfo.headPhoto
              : '@/assets/img/login/4.png'
          "
          alt=""
        />
        <img v-else src="@/assets/img/login/4.png" alt="" />
        <span class="name">{{
          userInfo.user.customerType === 1
            ? userInfo.account.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
            : userInfo.user.customerType === 2
            ? userInfo.name
            : userInfo.companyName
        }}</span>
        <DropdownMenu slot="list">
          <DropdownItem name="goUserCenter">用户中心</DropdownItem>
          <DropdownItem name="logOut">退出</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <Modal
      :scrollable="true"
      v-model="$store.state.alert"
      width="400px"
      class-name="my-center-modal"
      :footer-hide="true"
      @on-visible-change="visibleChange"
    >
      <div class="content">
        <Top :sonUser="sonUser" @cahngeAct="cahngeAct" :act="act"></Top>
        <Contents
          :sonUser="sonUser"
          @cahngeAct="cahngeAct"
          @changeAlert="changeAlert"
          :act="act"
          :flag="flag"
        ></Contents>
        <Footers
          @SetsonUser="SetsonUser"
          :sonUser="sonUser"
          :act="act"
        ></Footers>
      </div>
    </Modal>
  </header>
</template>

<script>
import { mapState } from "vuex";
import { logOut } from "../../../apis/login";
import Top from "../../main/components/top.vue";
import Contents from "../../main/components/contents.vue";
import Footers from "../../main/components/footers.vue";

export default {
  components: {
    Top,
    Contents,
    Footers,
  },
  data() {
    return {
      sonUser: 0,
      act: 1,
      flag: false,
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
  },
  methods: {
    visibleChange(e) {
      this.flag = e;
    },
    handleSearch(e) {
      if (!e) return;
      let url = this.$router.resolve({
        path: "/search",
        query: {
          paramStr: e,
        },
      });
      window.open(url.href, "_blank");
    },
    userClick(e) {
      this[e]();
    },
    async goUserCenter() {
      const res = await this.$apis.userServe.getUserInfo();
      this.$store.commit("loginInfo", {
        token: res.data.token,
        hasLogin: true,
        userInfo: res.content,
      });
      if (this.userInfo.user.customerType === 2) {
        let url = this.$route.resolve({
          url: "/user",
        });
        window.open(url.href, "_blank");
      } else {
        let url = this.$route.resolve({
          url: "/user/attestation",
        });
        window.open(url.href, "_blank");
      }
    },
    async logOut() {
      await logOut();
      this.$store.commit("logOut");

      // sessionStorage.setItem("token", "");
      this.$Message.success("退出成功");
      this.$router.go(0);
    },
    //login
    cahngeAct(e) {
      this.act = e;
    },
    changeAlert(e) {
      this.$store.commit("showAlert", e);
    },
    SetsonUser(e) {
      this.sonUser = e;
    },
    login(i) {
      this.act = i;
      // this.alert = true;
      this.$store.commit("showAlert", true);
    },
  },
};
</script>

<style lang="scss">
.header-container {
  .user {
    .ivu-select-dropdown {
      margin-top: 30px !important;
      margin-left: 30px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1500px) {
  .header-container {
    padding: 0 10px !important;
  }
}
.header-container {
  height: 99px;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 59px;
  .sign {
    img {
      width: 173px;
      height: 40px;
      margin-right: 11px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .select {
    width: 235px;
    height: 40px;
    background: #ffffff;
    opacity: 0.7;
    border-radius: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
    margin-left: 300px;
    position: relative;
    z-index: 99;
    a {
      color: #444444;
    }
    .ying {
      margin-left: 26px;
      display: inline-block;
      height: 100%;
      &:hover {
        & + .select-down {
          display: block;
          position: absolute;
          right: 0;
        }
      }
    }
    .select-down {
      display: none;
      width: 99px;
      background-color: #fff;
      a {
        display: block;
        white-space: nowrap;
        font-size: 14px;
        &:hover {
          background-color: #f3f3f3;
        }
      }
      &:hover {
        display: block;
        position: absolute;
        right: 0;
      }
    }
    &::v-deep .ivu-select-selection {
      border: none;
    }
    &::v-deep .ivu-select-selected-value {
      font-size: 20px;
    }
  }
  .user {
    font-size: 18px;
    color: #fefefe;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
      background: #f9f9f9;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      float: left;
    }
    .name {
      margin-top: 5px;
      margin-left: 8px;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      display: block;
      float: left;
      color: #ffffff;
    }
    .login {
      margin-right: 20px;
    }
    .register {
      display: inline-block;
      text-align: center;
      line-height: 40px;
      width: 120px;
      height: 40px;
      background: #00a4ff;
      border-radius: 20px;
    }
  }
  .search {
    width: 400px;
    height: 40px;
    &::v-deep .ivu-input {
      height: 40px;
      background-color: #fefefe;
      border: 1px solid #006db2;
      box-shadow: 0px 1px 8px 0px rgba(20, 122, 182, 0.4);
      border-radius: 35px;
      font-size: 16px;
      padding-left: 35px;
    }
    &::v-deep .ivu-input-icon {
      font-size: 2em;
      line-height: 40px;
      right: 30px;
      color: #006db2;
    }
  }
}
</style>
