<template>
  <div class="authentication-model-container">
    <div class="model">
      <div class="header">
        <span class="title">请完成认证</span>
        <span class="close" @click="handleClick">X</span>
      </div>
      <p>请尽快完成用户认证，认证通过后才可以发表文章等内容</p>
      <div class="footer">
        <Button type="primary" size="large" v-throttleClick long @click="handleSubmit"
          >前往认证</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reviewStatus: '',
    }
  },
  computed: {
    isEdited() {
      let bol1 = this.reviewStatus === -1 || this.reviewStatus === 3;
      let user = JSON.parse(sessionStorage.getItem("userInfo"));
      let bol2 =
        user.periodicalStatus === undefined
          ? true
          : user.periodicalStatus === 3
          ? true
          : false;
      return bol1 && bol2;
    },
  },
  methods: {
    handleClick() {
      this.$emit("handleClose");
    },
    async type() {
      let res = await this.$apis.userServe.userEditTypes();
      this.reviewStatus = res.content.status;
    },
    handleSubmit() {
      if (!this.isEdited) {
        this.$Message.warning('学者账号正在审核中');
        return
      };
      this.$router.push("/user/scholarCertification");
      this.$emit("handleClose");
    },
  },
  created() {
    this.type();
  },
};
</script>

<style lang="scss" scoped>
.authentication-model-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  .model {
    width: 500px;
    height: 200px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: bold;
      .close {
        cursor: pointer;
      }
    }
    p {
      font-size: 18px;
    }
  }
}
</style>
