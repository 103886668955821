<template>
  <div class="left-container">
    <div id="userLeft">
      <div class="user">
        <img v-if="userInfo.headPhoto" :src="$global.imgUrl + userInfo.headPhoto" alt="没有头像" />
        <img class="photo" v-else :src="require('@/assets/imgs/head_bg.png')" />
      </div>
      <div class="phone">
        {{
          userInfo.user.customerType === 1
          ? userInfo.account.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
          : userInfo.user.customerType === 2
            ? userInfo.name
            : userInfo.companyName
        }}
      </div>
      <div class="drop" @click="handleClick">
        <span class="release">发布</span>
        <Icon type="md-arrow-dropdown" />
        <ul class="navList" v-if="flag">
          <li v-for="(item) in navList" :key="item.id">
            <router-link :to="{ path: item.path, query: { title: item.name } }">{{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="tree">
        <Tree></Tree>
      </div>
    </div>
    <AuthenticationModel v-if="isFlag" @handleClose="isFlag = false"></AuthenticationModel>
  </div>
</template>

<script>
import Tree from "./tree";
import { mapState } from "vuex";
import AuthenticationModel from "@/views/form/components/AuthenticationModel.vue";
export default {
  components: {
    Tree,
    AuthenticationModel,
  },
  name: "left",
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.$bus.$on("mainDocument", this.handleDocument);
  },
  beforeDestroy() {
    this.$bus.$off("mainDocument", this.handleDocument);
  },
  data() {
    return {
      navList: [
        {
          id: 10,
          name: "资讯",
          path: "/informationForm",
        }, {
          id: 11,
          name: "论文",
          path: "/paperForm",
        }, {
          id: 62,
          name: "专著",
          path: "/monographForm",
        }, 
        // {
        //   id: 63,
        //   name: "会议成果",
        //   path: "/meetingForm",
        // }, 
        {
          id: 64,
          name: "专利",
          path: "/patentform",
        }, {
          id: 65,
          name: "科技报告",
          path: "/reportform",
        }, {
          id: 66,
          name: "标准",
          path: "/standardform",
        }, {
          id: 67,
          name: "其他成果",
          path: "/otherform",
        },
      ],
      flag: false,
      isFlag: false,
      headBg: require("@/assets/imgs/head_bg.png"),
    };
  },
  methods: {
    handleDocument(e) {
      if (e.target.className !== "release") {
        this.flag = false;
      }
    },
    handleClick() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
      if (userInfo.user.customerType === 1) {
        this.isFlag = true;
        this.flag = false;
      } else {
        this.flag = !this.flag;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#userLeft {
  height: 100%;
  width: 300px;
  //height: 100%;
  padding-top: 50px;
  background: #ffffff;
  border-right: 1px solid #999999;

  .tree {
    width: 160px;
    margin: 50px auto 0;
  }

  .drop {
    width: 160px;
    height: 46px;
    background: #ffffff;
    color: #00a4ff;
    text-align: center;
    line-height: 46px;
    border: 1px solid #00a4ff;
    margin: 25px auto 0;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    z-index: 9;
    font-size: 18px;
    color: #00a4ff;

    .navList {
      text-align: center;
      overflow: auto;
      margin: 5px 0;
      padding: 5px 0;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 1px 6px rgb(0 0 0 / 20%);

      li:hover {
        background: #f3f3f3;
      }

      a {
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    .release {
      margin-right: 4px;
    }
  }

  .phone {
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 15px auto 0;
    color: #333333;
    text-align: center;
  }

  .user {
    margin: 0 auto;
    width: 80px;
    height: 80px;

    //margin-top: 50px;
    //background: #F9F91111F9;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      //background: #F9F9F9;
    }
  }
}
</style>
