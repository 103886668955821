<template>
  <div id="user">
    <Header></Header>
    <div class="container">
      <Left></Left>
      <div class="right"><router-view></router-view></div>
    </div>
    <footer class="user-footer">
      <p>
        © 2021 百家在线数据集团有限公司 |
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="color: #fff"
        >
          京ICP备19039670号-2
        </a>
      </p>
    </footer>
  </div>
</template>

<script>
import Left from "./components/left";
import Header from "./components/Header";
import { mapState } from "vuex";

export default {
  name: "index",
  components: {
    Left,
    Header,
  },
  computed: {
    ...mapState(["token", "userInfo"]),
  },
  async created() {
    const res = await this.$apis.userServe.getUserInfo();
    if (res.resultCode === '200') {
      if (res.content.userStatus === 2) {
        document.title = res.content.name + "-百家在线";
      }
      this.$store.commit("loginInfo", {
        token: res.data.token,
        hasLogin: true,
        userInfo: res.content,
      });
      if (
        res.content.user.customerType == 2 &&
        this.$route.name == "attestationUser"
      ) {
        this.$router.push("/user/literature");
      }
    } else {
      this.$router.push('/')
    }
  },
};
</script>

<style lang="scss" scoped>
#user {
  min-width: 1440px;
  margin: 0 auto;
  .container {
    display: flex;
    background: #f9f9f9;
  }
  .right {
    width: 100%;
    min-width: 1100px;
    padding: 50px 0 120px 0px;
    margin:0 30px;
  }
  .user-footer {
    font-size: 14px;
    height: 132px;
    background: #444444;
    padding-top: 40px;
    text-align: center;
    p {
      color: #ffffff;
    }
  }
}
</style>
