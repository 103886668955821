<template>
  <div id="tree">
    <div class="list" @click="setActiveList(0)" v-if="this.userInfo.user.customerType !== 2">
      <router-link to="/user/attestation">
        <div>
          <img class="logo" src="@/assets/svg/icon-01.svg" alt />
          <div :class="activeList === 0 ? 'text' : 'defulttText'">认证</div>
          <div class="show"></div>
        </div>
      </router-link>
    </div>
    <div class="list">
      <div>
        <img class="logo" src="@/assets/svg/icon-02.svg" />
        <div
          @click="changeBlock('contentBlock')"
          :class="activeList === 100 ? 'text' : 'defulttText'"
        >
          管理
        </div>
        <img
          @click="changeBlock('contentBlock')"
          :class="contentBlock ? 'show' : 'fShow'"
          src="@/assets/imgs/1.png"
          alt
        />
      </div>
      <ul :style="{ height: contentBlock ? 'auto' : '0px' }" class="block">
        <li
          :class="active === 101 ? 'act' : ''"
          @click="setActiveList(100, 101)"
          v-if="this.userInfo.user.customerType === 2"
        >
          <span @click="to('/user/literature')">文献管理</span>
        </li>
        <li
          :class="active === 102 ? 'act' : ''"
          @click="setActiveList(100, 102)"
          v-if="this.userInfo.user.customerType === 2"
        >
          <span @click="to('/user/message')">资讯管理</span>
        </li>
        <li
          :class="active === 103 ? 'act' : ''"
          @click="setActiveList(100, 103)"
          v-if="this.userInfo.user.customerType === 2"
        >
          <span @click="to('/user/discussion')">讨论管理</span>
        </li>
        <li
          :class="active === 104 ? 'act' : ''"
          @click="setActiveList(100, 104)"
        >
          <span @click="to('/user/collect')">收藏管理</span>
        </li>
        <!--        <li-->
        <!--          :class="active === 100+index+1 ? 'act' : ''"-->
        <!--          @click="setActiveList(100, 100+index+1)"-->
        <!--        >-->
        <!--          <span @click="to('/user/messageMrg')">资讯管理</span>-->
        <!--        </li>-->
        <!--        <li-->
        <!--          :class="active === 100+index+1 ? 'act' : ''"-->
        <!--          @click="setActiveList(100, 100+index+1)"-->
        <!--        >-->
        <!--          <span @click="to('/user/collectMrg')">收藏管理</span>-->
        <!--        </li>-->
      </ul>
    </div>
    <div class="list">
      <div>
        <img class="logo" src="@/assets/svg/icon-03.svg" />

        <div
          @click="changeBlock('dataBlock')"
          :class="activeList === 200 ? 'text' : 'defulttText'"
        >
          数据
        </div>
        <img
          @click="changeBlock('dataBlock')"
          :class="dataBlock ? 'show' : 'fShow'"
          src="@/assets/imgs/1.png"
          alt
        />
      </div>
      <ul :style="{ height: dataBlock ? '80px' : '0px' }" class="block">
        <li
          :class="active === 201 ? 'act' : ''"
          @click="setActiveList(200, 201)"
        >
          <span @click="to('/user/attention')">关注数据</span>
        </li>
        <li
          :class="active === 202 ? 'act' : ''"
          @click="setActiveList(200, 202)"
        >
          <span @click="to('/user/fans')">粉丝数据</span>
        </li>

        <!--        <li-->
        <!--          :class="active === 100+index+1 ? 'act' : ''"-->
        <!--          @click="setActiveList(100, 100+index+1)"-->
        <!--        >-->
        <!--          <span @click="to('/user/messageMrg')">资讯管理</span>-->
        <!--        </li>-->
        <!--        <li-->
        <!--          :class="active === 100+index+1 ? 'act' : ''"-->
        <!--          @click="setActiveList(100, 100+index+1)"-->
        <!--        >-->
        <!--          <span @click="to('/user/collectMrg')">收藏管理</span>-->
        <!--        </li>-->
      </ul>
    </div>
    <div class="list">
      <div>
        <img class="logo" src="@/assets/svg/icon-04.svg" />

        <div
          @click="changeBlock('msgBlock')"
          :class="activeList === 300 ? 'text' : 'defulttText'"
        >
          消息
        </div>
        <img
          @click="changeBlock('msgBlock')"
          :class="msgBlock ? 'show' : 'fShow'"
          src="@/assets/imgs/1.png"
          alt
        />
      </div>
      <ul :style="{ height: msgBlock ? '80px' : '0px' }" class="block">
        <li
          :class="active === 301 ? 'act' : ''"
          @click="setActiveList(300, 301)"
        >
          <span @click="to('/user/system')">系统通知</span>
        </li>
        <li
          :class="active === 302 ? 'act' : ''"
          @click="setActiveList(300, 302)"
        >
          <span @click="to('/user/letter')">私信</span>
        </li>
      </ul>
    </div>
    <div class="list">
      <div>
        <img class="logo" src="@/assets/svg/icon-05.svg" />

        <div
          @click="changeBlock('setBlock')"
          :class="activeList === 400 ? 'text' : 'defulttText'"
        >
          设置
        </div>
        <img
          @click="changeBlock('setBlock')"
          :class="setBlock ? 'show' : 'fShow'"
          src="@/assets/imgs/1.png"
          alt
        />
      </div>
      <ul :style="{ height: setBlock ? 'auto' : '0px' }" class="block">
        <li
          :class="active === 401 ? 'act' : ''"
          @click="setActiveList(400, 401)"
        >
          <span @click="to('/user/user')">账号设置</span>
        </li>
        <li
          :class="active === 402 ? 'act' : ''"
          @click="setActiveList(400, 402)"
          v-if="this.userInfo.user.customerType === 2"
        >
          <span @click="to('/user/scholar')">学者信息</span>
        </li>
        <li
          :class="active === 403 ? 'act' : ''"
          @click="setActiveList(400, 403)"
          v-if="this.userInfo.user.customerType === 2"
        >
          <span @click="to('/user/privacy')">隐私设置</span>
        </li>
      </ul>
    </div>
    <div class="list" v-if="this.userInfo.user.customerType === 2">
      <div>
        <img class="logo" src="@/assets/svg/icon-06.svg" />

        <div
          @click="changeBlock('userBlock')"
          :class="activeList === 500 ? 'text' : 'defulttText'"
        >
          账号权益
        </div>
        <img
          @click="changeBlock('userBlock')"
          :class="userBlock ? 'show' : 'fShow'"
          src="@/assets/imgs/1.png"
          alt
        />
      </div>
      <ul :style="{ height: userBlock ? '80px' : '0px' }" class="block">
        <li
          :class="active === 501 ? 'act' : ''"
          @click="setActiveList(500, 501)"
        >
          <span @click="to('/user/honor')">荣誉认证</span>
        </li>
        <li
          :class="active === 502 ? 'act' : ''"
          @click="setActiveList(500, 502)"
        >
          <span @click="to('/user/claim')">学者认领</span>
        </li>
      </ul>
    </div>
    <div class="list" @click="setActiveList(600)">
      <router-link to="/user/history">
        <div>
          <img class="logo" src="@/assets/svg/icon-07.svg" alt />
          <div :class="activeList === 600 ? 'text' : 'defulttText'">
            历史记录
          </div>
          <div class="show"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "tree",
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      activeList: 0,
      active: null,
      contentBlock: false,
      userBlock: false,
      setBlock: false,
      msgBlock: false,
      dataBlock: false,
    };
  },
  methods: {
    setActiveList(num, act) {
      this.activeList = num;
      act ? (this.active = act) : (this.active = num + 1);
    },
    to(url) {
      this.$router.push(url);
      // if (this.userInfo.user.customerType !== 2) {
      //   this.$router.push("/user/collect");
      // } else {
      //   this.$router.push(url);
      // }
      console.log(url);
    },
    changeBlock(e) {
      this[e] = !this[e];
    },
  },
};
</script>

<style lang="scss" scoped>
.tree {
  .list {
    cursor: pointer;
    // margin-top: 0;

    margin-bottom: 40px;
    width: 150px;
    //background: yellow;

    .block {
      //background: pink;
      overflow: hidden;
      width: 150px;
      transition: height 0.5s;
      //color: #999999;
      
      //color: pink;
      .act {
        color: #00a4ff;
      }

      li {
        //background-color: #222;
        text-align: center;
        width: 150px;
        // height: 18px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        // line-height: 24px;
        margin-top:15px;
        margin-left:30px;
      }
    }

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .show {
        width: 19px;
        //margin-right: 20px;
        height: 10px;
        opacity: 0.4;
        transition: transform 0.5s;
      }

      .fShow {
        width: 19px;
        transform: rotate(180deg);
        transition: transform 0.5s;
        height: 10px;
        opacity: 0.4;
      }

      .logo {
        width: 20px;
        height: 22px;
      }

      .defulttText {
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        margin:0 10px;
      }

      .text {
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        color: #00a4ff;
        line-height: 24px;
        margin:0 10px;
      }
    }
  }
}
</style>
